// useRippleEffect.js
import {useState} from "react";

const useRippleEffect = () => {
  const [rippleStyle, setRippleStyle] = useState({});

  const handleRippleEffect = (e) => {
    // Get button's position relative to the viewport
    const rect = e.target.getBoundingClientRect();

    // Calculate position of the click relative to the button
    const rippleX = e.clientX - rect.left - 50;
    const rippleY = e.clientY - rect.top - 50;

    // Set the ripple effect position and class
    setRippleStyle({
      top: rippleY + "px",
      left: rippleX + "px",
      animation: "ripple-effect 0.8s linear",
    });

    setTimeout(() => {
      setRippleStyle({});
    }, 500);
  };

  return {rippleStyle, handleRippleEffect};
};

export default useRippleEffect;
