import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/utils";

const InviteCard = ({ invite, redTheme, type, templateClasses }) => {
  const discountPercentage = (mrp, sp) => {
    return Math.round(((mrp - sp) / mrp) * 100) + " % off" || "";
  };

  const getInviteDetails = () => {
    switch (type) {
      case "video":
        return {
          image: invite.thumbnail,
          name: invite.name,
          mrp: invite.mrp,
          sp: invite.sp,
          link: `/designs/wedding-video-invitation/${invite.slug}`,
        };
      case "pdf":
        return {
          image:
            Array.isArray(invite?.sampleImages) &&
            invite.sampleImages.length > 0
              ? invite.sampleImages[0]
              : null, // Fallback if no sampleImages are available
          name: invite.name,
          mrp: invite.mrp,
          sp: invite.sp,
          link: `/designs/wedding-pdf-invitation/${invite.slug}`,
        };
      case "website":
        return {
          image: invite.templateImage,
          name: invite.templateName,
          mrp: invite.crossPrice,
          sp: invite.price,
          link: `/template/${invite.templateId}`,
        };
      default:
        return {};
    }
  };

  const { image, name, mrp, sp, link } = getInviteDetails();

  return (
    <div>
      <Card className="border-0 shadow-none bg-transparent hidden sm:block ">
        <CardContent className="p-0 object-cover">
          <img
            src={image}
            alt={name}
            className={cn(
              `lg:h-[400px] lg:w-auto h-auto w-full object-contain lg:rounded-none rounded-xl`,
              templateClasses
            )}
            onClick={() => window.open(link, "_blank")}
          />
          <h3
            className={cn(
              `text-lightRed lg:text-lg text-[0.6rem] mt-1.5 font-semibold font-Assistant cursor-pointer truncate lg:mb-0 -mb-1.5`,
              redTheme ? "text-white" : ""
            )}
          >
            {name}
          </h3>

          <div
            className={cn(
              `inline-flex flex-wrap gap-1 md:text-base text-[0.6rem] pb-[5px]`,
              redTheme ? "text-white" : ""
            )}
          >
            <p className={cn(redTheme ? "text-white" : "")}>₹</p>
            <p
              className={cn(
                `text-gray-500 line-through`,
                redTheme ? "text-white/80" : ""
              )}
            >
              {mrp}
            </p>

            <p className={cn(redTheme ? "text-white" : "text-lightRed")}>
              {sp}
            </p>

            <p
              className={cn(
                `md:text-xs text-[8px] mt-auto lg:mb-0.5`,
                redTheme ? "text-white/80" : ""
              )}
            >
              {discountPercentage(parseInt(mrp), parseInt(sp))}
            </p>
          </div>
        </CardContent>
      </Card>
      <Card className="block sm:hidden shadow-lg w-[90%] h-auto m-auto mb-8 p-4 rounded-[10px]">
        <CardContent className="p-0 object-cover">
          <img
            src={image}
            alt={name}
            className="lg:h-[400px] lg:w-auto h-auto w-full object-contain lg:rounded-none rounded-xl"
            onClick={() => window.open(link, "_blank")}
          />
          <h3
            className={cn(
              `text-lightRed  text-[16px] my-4 font-semibold font-Inter cursor-pointer truncate -mb-1.5`,
              redTheme ? "text-white" : ""
            )}
          >
            {name}
          </h3>

          <div
            className={cn(
              `inline-flex flex-wrap gap-1 mt-2 font-Inter font-semibold text-lg`,
              redTheme ? "text-white" : ""
            )}
          >
            <p className={cn(redTheme ? "text-white" : "text-lightRed")}>
              ₹ {sp}
            </p>
            <p
              className={cn(
                `text-gray-500 line-through`,
                redTheme ? "text-white/80" : ""
              )}
            >
              {mrp}
            </p>

            <p
              className={cn(
                `text-sm font-Poppins font-medium mt-auto mb-0.5`,
                redTheme ? "text-white/80" : ""
              )}
            >
              {discountPercentage(parseInt(mrp), parseInt(sp))}
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default InviteCard;
