import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Heading, HyperLink } from "./common/heading";
import { cn } from "@/lib/utils";
import InviteCard from "./common/InviteCard";
import { useCallback,  useRef, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
const Websites = ({ invites, redTheme }) => {
  const properties = {
    duration: 2000,
    transitionDuration: 500,
    infinite: true,
    arrows: false,
  };

  const [index, setIndex] = useState(0);
  const slideRef = useRef(null);

  const handleIndicatorClick = useCallback((clickedIndex) => {
    if (slideRef.current) {
      slideRef.current.goTo(clickedIndex);
      setIndex(clickedIndex);
    }
  }, []);
  if (invites.length === 0) return null;
  return (
    <div>
      <div
        className={cn(
          "pl-4 pr-2 py-0.5  hidden sm:block",
          redTheme ? "bg-lightRed" : ""
        )}
      >
        <div className="flex justify-between items-center pr-2">
          <Heading
            title="Website Invites"
            classes={cn(redTheme ? "text-white" : "")}
          />
          <HyperLink
            link={`/designs/wedding-website-invitation`}
            redTheme={redTheme}
          />
        </div>
        <Carousel
          opts={{
            align: "start",
          }}
          className={cn(`w-full max-w-full`, redTheme ? "lg:pb-16" : "")}
        >
          <CarouselContent>
            {invites.map((invite, index) => (
              <CarouselItem
                key={index}
                className="basis-[28%] lg:basis-[15.666667%] hover:cursor-pointer"
              >
                <InviteCard
                  invite={invite}
                  redTheme={redTheme}
                  type="website"
                  templateClasses={'lg:h-[200px]'}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          {/* <CarouselPrevious /> */}
          {/* <CarouselNext /> */}
        </Carousel>
      </div>

      <div className="block sm:hidden">
        <div
          className={cn(
            "lg:mx-4 mb-8 py-0.5 relative",
            redTheme ? "bg-lightRed" : ""
          )}
        >
          <div className="flex justify-between items-center mb-4 mx-4">
            <Heading
             title="Website Invites"
              classes={cn(
                "",
                redTheme ? "text-white mt-0" : "text-lightRed  mt-0"
              )}
            />
           <HyperLink
            link={`/designs/wedding-website-invitation`}
            redTheme={redTheme}
          />
          </div>

          <div className="-mt-2">
            <Slide
              {...properties}
              onChange={(o, newIndex) => setIndex(newIndex)}
              ref={slideRef}
            >
              {invites.slice(0, 5).map((invite, index) => (
                <InviteCard invite={invite} redTheme={redTheme} type="website" />
              ))}
            </Slide>
          </div>
          <div className="mt-0">
            <div className="flex items-center justify-center gap-2">
              {invites.slice(0, 5).map((_, i) => (
                <div
                  key={i}
                  className={cn(
                    `size-2.5 rounded-full cursor-pointer`,
                    index === i ? "bg-lightRed" : "bg-[#EBEBEB]"
                  )}
                  onClick={() => {
                    handleIndicatorClick(i);
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Websites;

//   {
//     id: 1,
//     templateId: '1-a',
//     templateName: 'Classic',
//     crossPrice: '7999 ₹',
//     price: '99 ₹',
//     templateImage:
//       'https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/1-a.webp',
//     socialImage:
//       'https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp1-a.webp',
//     url: '/template/1',
//     isSelected: false,
//     theme: { bgColor: '#9CAB8D', textColor: '#000', textBgColor: '#fff' },
//     tabs: [ 'Premium' ],
//     images: {
//       weddingDayURL:
//         'url("https://wedding.nicdark.com/wedding-day/wp-content/uploads/sites/7/2023/03/gr-leaf-10.png")',
//       waterColorIMG: 'url(\'/images/bg-watercolor-02.jpg\')',
//       swiperSlide1: '/images/venders/avatar-02.png',
//       swiperSlide2: '/images/venders/logo-white-01.png',
//       swiperSlide3: '/images/venders/logo-white-02.png',
//       swiperSlide4: '/images/venders/logo-white-03.png',
//       swiperSlide5: '/images/venders/logo-white-04.png',
//       swiperSlide6: '/images/venders/logo-white-06.png',
//       paral02: 'url(\'/images/c-paral-02.jpg\')',
//       waterColor02: 'url(\'/images/bg-watercolor-02.jpg\')',
//       waterColor: 'url(\'/images/bg-watercolor.jpg\')',
//       double1:
//         'https://template-assets-sv.s3.amazonaws.com/template1/SNY_1945.JPG',
//       gallery10:
//         'https://template-assets-sv.s3.amazonaws.com/template1/DSC_4388.JPG',
//       leaf03: 'url(\'/images/gr-leaf-03.png\')'
//     }
//   },
