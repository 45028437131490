const Ripple = ({rippleStyle}) => (
  <span
    className="absolute bg-white opacity-50 rounded-full z-[11]"
    style={{
      width: "100px",
      height: "100px",
      transform: "scale(0)",
      ...rippleStyle,
    }}
  />
);

export default Ripple;
