const BouncyBalls = ({loading, className}) => {
  // Render the balls only if loading is true
  return loading ? (
    <div className="flex justify-center items-center">
      <div className="flex space-x-4">
        <div className={`ball ball1 ${className}`}></div>
        <div className={`ball ball2 ${className}`}></div>
        <div className={`ball ball3 ${className}`}></div>
      </div>
    </div>
  ) : null;
};

export default BouncyBalls;
